import { PlayerId } from "../../types";
import React, { useEffect } from "react";
import { useGame } from "./use-game";
import Lobby from "./lobby/lobby";
import QuickStart from "./quick-start/quick-start";
import CharacterSubmit from "./character-submit/character-submit";
import { GameMode } from "../../types2";
import OtpSubmit from "./character-submit/otp-submit";
import CreateStory from "./create-story/create-story";
import ReadingTime from "./game-voting/reading-time";
import GameResults from "./game-results/game-results";
import StoryCensor from "./story-title/story-censor";
import { useAppSelector } from "../../redux/store";
import { GameContext } from "../../contexts";
import StoryTitle from "./story-title/story-title";
import GamePage from "./game-page/game-page";
import Story from "./story-time/story";
import Minors from "./minors/minors";


type Props = {
    playerId: PlayerId
    playAgain: (happy: boolean) => void
    goAway: (text?: string) => void
}

export enum GameStatus {
    GameStatusIgnore = -2,
    GameStatusInvalid,

    GameStatusLobby,
    GameStatusPostLobby,

    GameStatusPreQuickStart,
    GameStatusQuickStart,
    GameStatusPostQuickStart,

    GameStatusMinors,
    GameStatusPostMinors,

    GameStatusPreCharacters,
    GameStatusCharacters,
    GameStatusPostCharacters,

    // GameStatusPrePrompts,
    // GameStatusPrompts,
    // GameStatusPostPrompts,

    GameStatusPreStoryCreate,
    GameStatusStoryCreate,
    GameStatusPostStoryCreate,

    GameStatusPreStoryWrite,
    GameStatusStoryWrite,
    GameStatusPostStoryWrite,

    GameStatusPreStoryTitle,
    GameStatusStoryTitle,
    GameStatusPostStoryTitle,

    GameStatusPreCensoring,
    GameStatusCensoring,
    GameStatusPostCensoring,

    GameStatusPreVoting,
    GameStatusReacting,
    GameStatusVoting,
    GameStatusPostReading,

    GameStatusPreResults,
    GameStatusResults,
}


const GameManager = ({ playerId, goAway }: Props) => {

    const selectGameStatus = useAppSelector(state => state.game.game?.status)
    const selectGameSettings = useAppSelector(state => state.game.game?.settings)

    const playerStatus = useAppSelector(state => state.player.player?.status)

    const gameConn = useGame(playerId, goAway)

    function getComponent() {

        if (playerStatus === GameStatus.GameStatusInvalid) {
            return <GamePage titles={["oops"]} />
        }

        switch (selectGameStatus) {
            case GameStatus.GameStatusLobby:
            case GameStatus.GameStatusPostLobby:
                return <Lobby />
            case GameStatus.GameStatusPreQuickStart:
            case GameStatus.GameStatusQuickStart:
            case GameStatus.GameStatusPostQuickStart:
                return <QuickStart />
            case GameStatus.GameStatusMinors:
            case GameStatus.GameStatusPostMinors:
                return <Minors />
            case GameStatus.GameStatusPreCharacters:
                return <GamePage titles={["Getting game ready..."]} />
            case GameStatus.GameStatusCharacters:
            case GameStatus.GameStatusPostCharacters:
                if (selectGameSettings?.game_mode === GameMode.otp || (selectGameSettings?.game_mode === GameMode.solo_mode && !selectGameSettings.self_shuffle)) {
                    return <OtpSubmit onSubmit={gameConn.sendMessage} />
                } else {
                    return <CharacterSubmit onSubmit={gameConn.sendMessage} />
                }
            case GameStatus.GameStatusPreStoryCreate:
            case GameStatus.GameStatusStoryCreate:
            case GameStatus.GameStatusPostStoryCreate:
                return <CreateStory />
            case GameStatus.GameStatusPreStoryWrite:
            case GameStatus.GameStatusStoryWrite:
            case GameStatus.GameStatusPostStoryWrite:
                return <Story />
            case GameStatus.GameStatusPreStoryTitle:
            case GameStatus.GameStatusStoryTitle:
            case GameStatus.GameStatusPostStoryTitle:
                return <StoryTitle />
            case GameStatus.GameStatusPreCensoring:
            case GameStatus.GameStatusCensoring:
            case GameStatus.GameStatusPostCensoring:
                return <StoryCensor />
            case GameStatus.GameStatusPreVoting:
            case GameStatus.GameStatusReacting:
            case GameStatus.GameStatusVoting:
            case GameStatus.GameStatusPostReading:
                return <ReadingTime />
            case GameStatus.GameStatusResults:
            case GameStatus.GameStatusPreResults:
                return <GameResults />
            case GameStatus.GameStatusInvalid:
                return <GamePage titles={["oops"]} />
            default:
                return <GamePage titles={["Loading..."]} />
        }

    }

    useEffect(() => {
        if (playerStatus === GameStatus.GameStatusInvalid) {
            goAway()
        }
    }, [gameConn, playerStatus, goAway])

    return <GameContext.Provider value={{ submit: gameConn.sendMessage }}>
        {getComponent()}
    </GameContext.Provider>

}

export default GameManager