import React from 'react';
import "./styles.css"
import {BlogContent} from "../blog/blog";
import {ViewCounter} from "../../common/view-counter/view-counter";
import {Link} from "react-router-dom";
import MenuPage from "../menu-page/menu-page";
import {useAppSelector} from "../../../redux/store";
import {Trans, useTranslation} from 'react-i18next';
import {usePremium} from "../auth/is_loggedin";
import AvatarProfile2 from "../../common/avatar-profile/avatar-profile2";
import LinkButton from "../link-button/link-button";
import {config} from "../../../constants";

type Props = {
    onSubmit: () => void
    onName: () => void
}


const Main = ({onSubmit}: Props) => {

    const {t} = useTranslation('main')

    const premium = usePremium()

    let selectConfig = useAppSelector(state => state.meta.config);

    let selectPlayerDetails = useAppSelector(state => state.menu.playerDetails);


    function handleStart() {
        onSubmit()
    }

    function footer() {

        return <div className={"flex-1 flex justify-between flex-row items-center"}>
            <div className={"ml-1 flex justify-center items-center space-x-1"}>
                <Trans i18nKey={"footers.discord"} t={t}>
                    discord<a href='https://discord.gg/eNs8dgUkF3' target='_blank'
                              rel="noreferrer">discord</a>
                </Trans>
                <span>|</span>
                <a href='https://bsky.app/profile/franticfanfic.com' target='_blank'
                              rel="noreferrer">bluesky</a>
                {/* <Trans i18nKey={"footers.bluesky"} t={t}>
                    bluesky <a href='https://bsky.app/profile/franticfanfic.com' target='_blank'
                              rel="noreferrer">bluesky</a>
                </Trans> */}
                <span>|</span>
                <Trans i18nKey={"footers.tiktok"} t={t}>
                    tiktok <a href='https://www.tiktok.com/@franticfanfic' target='_blank'
                              rel="noreferrer">tiktok</a>
                </Trans>
                <span>|</span>

                <Trans i18nKey={"footers.instagram"} t={t}>
                    instagram <a href='https://www.instagram.com/franticfanfic' target='_blank'
                              rel="noreferrer">instagram</a>
                </Trans>
                {/*<span>|</span>*/}

                {/*<Trans i18nKey={"footers.bug"} t={t}>*/}
                {/*    tiktok <a href='https://www.tiktok.com/@franticfanfic' target='_blank'*/}
                {/*              rel="noreferrer">tiktok</a>*/}
                {/*</Trans>*/}
            </div>
            <ViewCounter/>
        </div>
    }

    return <MenuPage hideAvatar disableBack subHeaders={[t('subheaders.default')]} titles={[t('headers.default')]}
                     footerJsx={footer()}>
        <div className={"relative flex flex-col items-stretch"}>
            {selectConfig?.notice_message && <div className={"fake-button text-2xl leading-none mb-1 break-words"}>
                <p>{selectConfig.notice_message}</p>
            </div>}
            {selectConfig?.maintenance &&
                <div className={"fake-button text-4xl leading-tight flex-grow-[3]"}>
                    <p className={"text-3xl leading-tight break-words"}>{selectConfig.maintenance_message}</p>
                </div>}


            <div className={"flex-shrink-0"}>
                {<AvatarProfile2/>}
            </div>
            <div className={"page-main-buttons"}>

                <LinkButton path={"/user"}
                            buttonText={selectPlayerDetails && selectPlayerDetails?.name.length > 0 ? t('body.editName') : t('body.name')}/>

                <button
                    disabled={(selectConfig?.maintenance && localStorage.getItem("testing") === null) && !config.development}
                    type="button" onClick={handleStart}
                    className='page-main-button'>{t('body.start')}
                </button>


                <LinkButton path={"/supporter"}
                            buttonText={premium ? t('body.auth2') : t('body.auth')}/>


                <LinkButton path={"/howtoplay"}
                            buttonText={t('body.help')}/>

                <LinkButton path={"/bugreport"}
                            buttonText={'report a bug'}/>

                {/*<LinkButton path={"/about"}*/}
                {/*            buttonText={t('body.about')}/>*/}

            </div>




            <div className={"relative"}>
                <div className={"page-main-blog mt-8"}>
                    <BlogContent/>
                </div>
            </div>
            <div className={"relative flex-shrink-0 self-center"}>
                <Link className={"terms-link"} to={"/terms"}>{t('body.terms')}</Link>
                <Link className={"terms-link"} to={"/privacy"}>{t('body.privacy')}</Link>
                <Link className={"terms-link"} to={"/codeofconduct"}>{t('body.codeOfConduct')}</Link>
                <Link className={"terms-link"} to={"/contact"}>{t('body.contact')}</Link>
                <Link className={"terms-link"} to={"/about"}>{t('body.about')}</Link>
            </div>

        </div>

    </MenuPage>


}

export default Main
