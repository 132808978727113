import { useCallback, useEffect, useRef, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { config } from '../../constants';
import { ConnectionStatus, updateConnectionStatus, updateError, updateLoading } from "../../redux/slices/meta";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { GameMessage, PlayerAction, PlayerId, PlayerMessage } from "../../types";
import moment from 'moment';
import { GameStatus } from './game';
import { updatePlayer } from '../../redux/slices/player';
import { updateGame } from '../../redux/slices/game';

// This will be a simplified version that will later use react-use-websocket
interface UseGameReturn {
  sendMessage: (playerAction: PlayerAction, noLoading?: boolean) => void;
  close: () => void;
}

export function useGame(
  playerId: PlayerId,
  goAway: () => void,
): UseGameReturn {
  const dispatch = useAppDispatch();

  const [connect, setConnect] = useState<boolean>(true);
  const loadTimer = useRef<NodeJS.Timeout>()

  const [socketUrl] = useState(`${config.wsHost}/rooms?player_id=${playerId.id}&player_secret=${playerId.secret}&game_id=${playerId.game_id}`);


  // Get state from Redux store
  const gameState = useAppSelector(state => state.game);
  const playerState = useAppSelector(state => state.player.player);
  const menuState = useAppSelector(state => state.menu);

  const gameActive = useRef(false);


  const { sendJsonMessage, readyState } = useWebSocket(socketUrl, {
    onMessage: (event) => { handleMessage(event.data) },
    onClose: (event) => {
      if (event.wasClean) {
        console.log('WebSocket closed cleanly');
      } else {
        // Connection died unexpectedly
        console.log('WebSocket connection died');
        dispatch(updateConnectionStatus(ConnectionStatus.CONNECTION_LOST));
      }
    },
    onError: (event) => {
      console.error('WebSocket error:', event);
      dispatch(updateConnectionStatus(ConnectionStatus.CONNECTION_ERROR));
    },
    onOpen: () => {
      console.log('WebSocket connection established');
      dispatch(updateConnectionStatus(ConnectionStatus.CONNECTED));
    },
    reconnectAttempts: 5,
    shouldReconnect: (closeEvent) => !closeEvent.wasClean,
    reconnectInterval(lastAttemptNumber) {
      return Math.min(1000 * 2 ** lastAttemptNumber, 30000);
    },
    retryOnError: true,
    // heartbeat: {
    //   // Send heartbeat message every 30 seconds
    //   message: JSON.stringify({ type: 'ping' }),
    //   interval: 30000,
    //   // Optional: wait for pong response
    //   timeout: 10000
    // },
  }, connect)


  // Setup beforeunload event handler
  useEffect(() => {
    // Set gameActive based on game status when game state changes
    gameActive.current = readyState === ReadyState.OPEN || readyState === ReadyState.CONNECTING



    // Define the handler for beforeunload
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      // Only show confirmation if the game is active
      if (gameActive.current) {
        // Standard way to show confirmation in modern browsers
        e.preventDefault();

        // For older browsers
        const confirmationMessage = 'You are in the middle of a game. Are you sure you want to leave?';
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    // Add the event listener
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [readyState]);

  useEffect(() => {
    switch (readyState) {
      case ReadyState.CONNECTING:
        dispatch(updateConnectionStatus(ConnectionStatus.CONNECTING));
        break;
      case ReadyState.OPEN:
        dispatch(updateConnectionStatus(ConnectionStatus.CONNECTED));
        break;
      case ReadyState.CLOSING:
        dispatch(updateConnectionStatus(ConnectionStatus.DISCONNECTED));
        break;
      case ReadyState.CLOSED:
        dispatch(updateConnectionStatus(ConnectionStatus.DISCONNECTED));
        break;
      case ReadyState.UNINSTANTIATED:
        // Initial state, websocket instance not created yet
        break;
    }
  }, [readyState, dispatch]);

  const handleMessage = useCallback((data: any) => {
    // lastActivity.current = Date.now();
    dispatch(updateLoading(false));
    dispatch(updateConnectionStatus(ConnectionStatus.CONNECTED));

    try {
      const gameMessage = JSON.parse(data) as GameMessage;
      // Handle acknowledgments and errors
      if (loadTimer.current) {
        clearTimeout(loadTimer.current);
        loadTimer.current = undefined;
      }

      // Handle goaway messages
      if (gameMessage.goaway) {
        // gameDone.current = true;
        // close();
        setConnect(false)

        goAway();
        return;
      }

      // Handle time synchronization
      if (gameMessage.now) {
        const offset = new Date(gameMessage.now).getTime() - Date.now();
        moment.now = function () { return offset + Date.now(); };
      }


      if (gameMessage.error) {
        dispatch(updateError(gameMessage.error));
      } else if (gameMessage.ack) {
        dispatch(updateError(""));
      }

      if (gameMessage.game !== undefined) {
        if (gameMessage.game.status === GameStatus.GameStatusInvalid) {
          // gameDone.current = true;
          setConnect(false)
          goAway();
        } else if (gameMessage.game.status === GameStatus.GameStatusResults) {
          // gameDone.current = true;
          setConnect(false)
        }
        dispatch(updateGame(gameMessage.game));
      }
      if (gameMessage.player !== undefined) {
        if (gameMessage.player.status === GameStatus.GameStatusInvalid) {
          // gameDone.current = true;
          setConnect(false)
          goAway();
        } else {
          dispatch(updatePlayer(gameMessage.player));
        }
      }


    } catch (err) {
      console.error("Error parsing message", err, data);
    }
  }, [dispatch, goAway]); // Only truly required dependencies



  return {
    sendMessage: function (playerAction: PlayerAction, noLoading?: boolean): void {
      if (!gameState.game?.id || !playerState?.id || !menuState.playerId?.secret) {
        console.warn("Cannot send message - missing game or player info");
        return;
      }
      const playerMessage: PlayerMessage = {
        game_id: gameState.game.id,
        player_action: playerAction,
        player_id: playerState.id,
        player_secret: menuState.playerId.secret
      };
      sendJsonMessage(playerMessage);

      if (!noLoading) {
        dispatch(updateLoading(true));

        // Clear any existing timer
        if (loadTimer.current) {
          clearTimeout(loadTimer.current);
        }

        // Set new timeout
        loadTimer.current = setTimeout(() => {
          dispatch(updateLoading(false));
          dispatch(updateError("Request timed out"));
        }, 5000);
      }
    },
    close: function (): void {
      setConnect(false)
    }
  }
}