import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Player } from "../../types";

export interface PlayerState {
    player?: Player
}


const initialPlayerState: PlayerState = {
    player: undefined
}


const playerSlice = createSlice({
    name: "playerSlice",
    initialState: initialPlayerState,
    reducers: {
        updatePlayer: (state, action: PayloadAction<Player>) => {
            action.payload.characters?.sort((a, b) => a.meta.locked ? 100000 : (b.meta.locked ? -100000 : (a.id - b.id)))

            action.payload.current_story?.submissions?.sort((a, b) => a.index - b.index)

            // state.player = {...state.player, ...action.payload}

            state.player = action.payload

        }
    }
})

export const { updatePlayer } = playerSlice.actions
export const playerReducer = playerSlice.reducer
