import React from "react";
import Body from "../../components/common/body/body";
import BodyText from "../../components/common/body-text/body-text";

export let blog11Title = "Entry #11 - Scrambles, Poulet and Moderation!"

export let blog11Author = "Posted by Michael / Friday June 11 2021"

export let blog11Content = <Body>
    <BodyText>Hi Frantic Fanficers! Another week passes by already! Can you believe it's been six months since we
        started
        working on this silly fanfiction game? Time has really flown by! </BodyText>

    <BodyText>We have two introductions to make!</BodyText>
    <BodyText>Scrambles is the character voted on by our <a href={"https://ko-fi.com/franticfanfic"} target="_blank"
                                                            rel="noreferrer">Ko-Fi</a> supporters. Opossum won the most
        votes!
        And we
        asked on our <a href="https://bsky.app/profile/franticfanfic.com" target="_blank"
                        rel="noreferrer">Bluesky</a> if anyone has some good name ideas, we picked the one we liked the
        most!
        That's the last Ko-Fi supported character we're going to release for a while. The next Ko-Fi goal is something
        really big - a brand new mode that builds on what we've done with Frantic Fanfic so far. It's gonna be
        great!</BodyText>
    <BodyText>Poulet is a crossover character on loan from our friend at <a
        href="https://store.steampowered.com/app/1618510/Poulet_Poulet" target="_blank" rel="noreferrer">Poulet
        Poulet</a>. His game comes out in November of this year, and when it does you'll be able to play as Wormman! How
        cool is that?!</BodyText>


    <BodyText>We also introduced a new advanced setting to better support streamers: Moderation.</BodyText>
    <BodyText>With the moderation setting enabled all players will be given the option to flag a 'censor' to previous
        parts
        during
        the writing phase.
        After titling, the VIP will be able to review all censored submissions and block if they deem them
        inappropriate.
        This is meant to give the VIP all the power but have the players collaborate and flag parts if needed.

        Additionally, during reading/reacting the VIP will still be able to censor/uncensor submissions for all players
        just in case something was missed.
        Once a part has been censored in the reaction stage of the game that part will be redacted from the end results
        page and the download options.

    </BodyText>

    <BodyText>In this code push we have also addressed an issue with rejoining games that we've heard reports of being
        inconsistent.
        Incidents we have heard of are players (mostly using a mac device) becoming
        unable to rejoin the game after starting a story. Rejoining from the same browser tab (without closing down all
        tabs, etc) should work more seamlessly now.
        If you rejoin from a different
        device, it should still work provided there isn't an existing browser connected to the game.</BodyText>

    <BodyText>We also made some small changes like fixing alignments here and there. We want to thank everyone following
        us on
        Twitch and Bluesky! Means a lot :&gt;
        Also big shoutout to Fujocon for letting us host a gaming panel! Zee will see you at FujoCon this
        weekend! </BodyText>

    <BodyText>Zee and Michael</BodyText>

</Body>

export let blog = {
    title: blog11Title,
    author: blog11Author,
    content: blog11Content
}