import React from "react";
import wormhat from "../../images/wormhat.png";
import wormboxers from "../../images/wormboxers.png";
import raccoonhat from "../../images/raccoonhat.png";
import Body from "../../components/common/body/body";
import BodyText from "../../components/common/body-text/body-text";
import BodyTitle from "../../components/common/body-title/body-title";
import BodyList, {BodyListItem} from "../../components/common/body-list/body-list";


export let blogTitle = "Entry #14 - Why do we make so many updates?!"

export let blogAuthor = "Posted by Michael / Wednesday June 30 2021"

export let blogContent = <Body>
    <BodyText>ANSWER:</BodyText>
    <BodyText>Because we don’t sleep that’s why. Just like ants. Speaking of ants… Look! You can have Wormman on a HAT!
        Worm
        hat!!!! Buy a Wormman hat. Support Frantic Fanfic!!</BodyText>
    <a
        href="https://www.etsy.com/ca/listing/1028978342/wormhat-unisex-twill-hat"> <img src={wormhat} alt={wormhat}
                                                                                         width={"100%"}
                                                                                         height={"auto"}/></a>

    <BodyText>Okay now that that plug is done… we’re updating the site with a bunch of changes! Hopefully everything
        doesn’t
        break as this weekend was a lot of making sure that everything didn’t break… and then it did… oh man. Anyhoo!
        Here’s the changes!
    </BodyText>

    <BodyTitle>NEW FEATURES:
    </BodyTitle>
    <BodyList>
        <BodyListItem>We cleaned up the settings screen! It should look better now. There’s also new settings
            available!</BodyListItem>
        <BodyList>
            <BodyListItem>We added the ability to increase the number of swaps your fanfic goes through, so you can have
                more than
                three if you want.
            </BodyListItem>
            <BodyListItem>We also added a choice to increase the number of characters that can be written about from 2
                to 3 for all
                your OT3 needs.
            </BodyListItem>
            <BodyListItem>The option to have your own characters included in your choices has been added. For people who
                like to laugh
                at their own jokes. Like me. Hahahaha.
            </BodyListItem>
        </BodyList>
        <br/>

        <BodyListItem>We added the DONE flag! This was a highly requested feature. While in writing mode if all users
            click the
            DONE flag, the game automatically knows you’re all done your part and they save and switch! No more asking
            the VIP to skip the stories ahead, if you’re all done and you all hit the button you’ll advance. But if you
            start typing after you click the done flag, it goes back to being… undone… and you have to click it again.
        </BodyListItem>
        <br/>

        <BodyListItem>We sped up the transition screen slightly and got rid of that dead space before the “You’re
            writing….” text
            appears. It’s not a big deal, it just bothered us a bit for wasting time is all, lolol.
        </BodyListItem>
        <br/>


        <BodyListItem>Some people were getting confused thinking that the game was down, but it wasn’t down, they just
            didn’t
            enter their name yet. So we fixed the flow there.Instead of having a greyed out “START”, people can click
            that and go directly to the “NAME” screen. We think it’s less confusing than seeing the greyed out “START”
            option for new players.
        </BodyListItem>

        <br/>

        <BodyListItem>By popular demand we’re also enabling play with 2 players. In this situation you’ll only have 2
            stories
            written and all the characters you write will be sent to the other person, there’s no shuffling involved.
            You’ll be writing the beginning and ending of your fic, with the middle swapped out to the other player.
            It’s a quieter game experience and the voting is a little wonky but if you can’t find another player it
            works for now ahaha.
        </BodyListItem>
        <br/>

        <BodyListItem>Because of all the AMAZING titles that people have come up with, we’ve introduced a title bonus
            vote. So
            there’s a personal favourite bonus vote, title vote, and some random bonus vote that we came up with…
            speaking of those...
        </BodyListItem>
        <br/>

        <BodyListItem>Three new bonus vote categories have been added! They’re very silly. Please enjoy!</BodyListItem>
        <br/>

    </BodyList>
    <BodyTitle>Bug Fixes:</BodyTitle>
    <BodyList>
        <BodyListItem>We were alerted to a bug with Iphone users not being able to see the first two buttons and we were
            able to
            look into it, so that has been fixed now. Side note: this was fixed using the old iphone that Zee had, but
            it’s not a recent Iphone so if any users still experience this bug please do contact us so we can
            troubleshoot as we don’t own one that came out in the last 5 years…
        </BodyListItem>
        <br/>
        <BodyListItem>Michael fixed another instance of the “LOADING…” bug that’s been bothering a few people. Thanks
            Michael!!
        </BodyListItem>
        <br/>
        <BodyListItem>A bug hid the “HIDE ROOM CODE FOR STREAMERS” option in our “ENTER GAME CODE” section, so Michael
            fixed that
            as well.
        </BodyListItem>
    </BodyList>
    <BodyTitle>CONCLUSION:</BodyTitle>
    <BodyText>As always, you can support the team (I say team but it’s two of us lol.. Is two a team? Yeah probably!!)
        by
        sending us a <a
            href={"https://ko-fi.com/franticfanfic"} target="_blank"
            rel="noreferrer">Ko-Fi</a> or by buying something from us from <a
            href={"https://www.etsy.com/ca/shop/FranticFanfic"} target="_blank"
            rel="noreferrer">Etsy</a>, or following us on <a
            href={"https://bsky.app/profile/franticfanfic.com"}>Blueksy</a> or! Or! By streaming the
        game, telling your friends or your favourite streamer about it! All those things are GREAT! Thanks so much and
        happy fanficing!
    </BodyText>
    <BodyText>Oh hey you made it to the bottom of the blog update. Neat. Look at these WORMMAN BOXERS!!!!</BodyText>
    <a
        href="https://www.etsy.com/ca/listing/1044013321/wormman-worm-boxer-briefs"> <img src={wormboxers}
                                                                                          alt={wormboxers}
                                                                                          width={"100%"}
                                                                                          height={"auto"}/></a>
    <BodyText>Also… RACCOON HAT!!!</BodyText>
    <a
        href="https://www.etsy.com/ca/listing/1043959529/purple-raccoon-trash-panda-unisex-twill"><img src={raccoonhat}
                                                                                                       alt={raccoonhat}
                                                                                                       width={"100%"}
                                                                                                       height={"auto"}/></a>

    <BodyText>oKAY That’s all the plugs we got. Thank you again!</BodyText>
    <BodyText>Zee and Michael</BodyText>
</Body>

export let blog = {
    title: blogTitle,
    author: blogAuthor,
    content: blogContent
}