import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Config, DefaultGameSettings, TwitchStreamer } from "../../types";

export interface MetaState {
    loading: boolean
    error?: string
    connected: boolean
    count?: number
    connectionStatus: ConnectionStatus  // Add new field

    config?: Config
    streamers?: TwitchStreamer[]
    settings?: DefaultGameSettings

}

// In redux/slices/meta.ts
export enum ConnectionStatus {
    DISCONNECTED = "disconnected",
    CONNECTING = "connecting",
    CONNECTED = "connected",
    RECONNECTING = "reconnecting",
    CONNECTION_ERROR = "connection_error",
    CONNECTION_LOST = "connection_lost",
    CONNECTION_FAILED = "CONNECTION_FAILED"
}

const initialMetaState: MetaState = {
    loading: false,
    connected: false,
    error: undefined,
    connectionStatus: ConnectionStatus.DISCONNECTED // Instead of connected: boolean
}


const metaSlice = createSlice({
    name: "metaSlice",
    initialState: initialMetaState,
    reducers: {
        updateError: (state, action: PayloadAction<string>) => {
            state.error = action.payload
        },

        updateLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        updateCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload
        },
        updateConnected: (state, action: PayloadAction<boolean>) => {
            state.connected = action.payload
        },
        updateConnectionStatus: (state, action: PayloadAction<ConnectionStatus>) => {
            state.connectionStatus = action.payload
            // Keep the boolean flag in sync for backward compatibility
            state.connected = action.payload === ConnectionStatus.CONNECTED
        },
        updateConfig: (state, action: PayloadAction<Config>) => {
            state.config = action.payload
        },
        updateStreamers: (state, action: PayloadAction<TwitchStreamer[]>) => {
            state.streamers = action.payload
        },

        updateDefaultGameSettings: (state, action: PayloadAction<DefaultGameSettings>) => {
            state.settings = action.payload
        }

    }
})

export const {
    updateConnected,
    updateConnectionStatus,
    updateError,
    updateLoading,
    updateConfig,
    updateStreamers,
    updateDefaultGameSettings,
    updateCount
} = metaSlice.actions
export const metaReducer = metaSlice.reducer
