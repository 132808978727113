import { combineReducers, configureStore, createAction } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { gameReducer } from "./slices/game";
import { playerReducer } from "./slices/player";
import { metaReducer } from "./slices/meta";
import { menuReducer } from "./slices/menu";

// export const history = createBrowserHistory()

const createRootReducer = () => combineReducers({
    // router: connectRouter(history),
    game: gameReducer,
    player: playerReducer,
    meta: metaReducer,
    menu: menuReducer,
})


export const store = configureStore({
    reducer: createRootReducer(),
    // middleware: (getDefaultMiddleware => getDefaultMiddleware().concat(routerMiddleware(history)))
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}

export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector