import React from "react";

import wormhat from "../../images/wormhat.png"
import possumhat from "../../images/possumhat.png"
import Body from "../../components/common/body/body";
import BodyText from "../../components/common/body-text/body-text";

export let blogTitle = "Entry #13 - SCHEDULED MAINTENANCE."

export let blogAuthor = "Posted by Michael / Monday June 28 2021"

export let blogContent = <Body>
    <BodyText>HELLO! We're taking the site down at 6AM EST Monday June 28th for some much needed maintenance and
        back-end bug
        fixing. We've been doing our best with stealth fixes over the weekend but there's some that need to be
        tackled when no one is playing as they can potentially break the game and corrupt stories.</BodyText>

    <BodyText><b>PLEASE DO NOT TRY TO CREATE A GAME FROM 6AM-7AM EST TODAY / UNTIL YOU SEE THE MAINTENANCE MODE BUTTON
        DISAPPEAR.</b></BodyText>

    <BodyText>On another note: HEY! Thanks for playing!!! We set up an Etsy and you can buy cool Frantic Fanfic themed
        stuff
        there. We just got it started so if you have specific characters you want to buy merch of, LET US KNOW on our
        Twitter! In the meantime... Look at this WORMMAN hat! Don't you want a <a
            href="https://www.etsy.com/ca/listing/1028978342/wormhat-unisex-twill-hat">WORMMAN HAT?!</a></BodyText>

    <a
        href="https://www.etsy.com/ca/listing/1028978342/wormhat-unisex-twill-hat"> <img src={wormhat} alt={wormhat}
                                                                                         width={"100%"}
                                                                                         height={"auto"}/></a>


    <BodyText>Or... Is Wormman not your style (GASP HOW COULD YOU!)? Don't worry. Scrambles has got you covered!!! Go
        Scrambles!! We believe in you!</BodyText>

    <a
        href="https://www.etsy.com/ca/listing/1029003788/scrambles-the-opossum-unisex-twill-hat?"><img src={possumhat}
                                                                                                       alt={possumhat}
                                                                                                       width={"100%"}
                                                                                                       height={"auto"}/></a>


    <BodyText>Also, if you like the game and want to drop us a "thanks" we do have our <a
        href={"https://ko-fi.com/franticfanfic"} target="_blank"
        rel="noreferrer">Ko-Fi</a>! BUT YEAH! Thanks for playing and
        thanks for telling all your friends about us!! </BodyText>

    <BodyText>And super special THANKS for everyone who has tweeted at us and let us know about bugs on our <a
        href={"https://bsky.app/profile/franticfanfic.com"}>Bluesky</a> and on <a href={"https://www.tiktok.com/@zeekayart?"}>Zee's
        TikTok</a>. And to all the streamers who we dropped in on! Thanks for streaming the game! We really appreciate
        it.</BodyText>

    <BodyText>Zee and Michael</BodyText>

</Body>

export let blog =
    {
        title: blogTitle,
        author: blogAuthor,
        content: blogContent
    }