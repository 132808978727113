import React from "react";
import Body from "../components/common/body/body";
import BodyText from "../components/common/body-text/body-text";
import StyledHeader from "../components/common/styled-header/styled-header";


export const body = {


    content: <Body>
        {/*CONTACT BELOW*/}
        <StyledHeader header={"EMAIL"} subHeader={"For inquiries."}/>
        {/*<BodyTitle><u><i>EMAIL</i></u></BodyTitle>*/}
        <BodyText>Contact us at franticfanficgame [at] gmail [dot]
            com.</BodyText>
        <BodyText>Please submit bug reports via the form on our website.</BodyText>

        {/*<BodyTitle><i><u></u> </i></BodyTitle>*/}
        <StyledHeader header={"SOCIAL"} subHeader={"Yell at us on Blue Sky."}/>
        <BodyText>Game Creator: <br/>
            <a href={"https://bsky.app/profile/zeekayart.bsky.social"}>@zeekayart.bsky.social</a></BodyText>
        <BodyText>Game Developer: <br/>
            <a href={"https://bsky.app/profile/michael.franticfanfic.com"}>@michael.franticfanfic.com</a></BodyText>
        <BodyText>Frantic Fanfic: <br/>
            <a href={"https://bsky.app/profile/franticfanfic.com"}>@franticfanfic.com</a></BodyText>
        {/*<BodyTitle><i> <u> </u> </i></BodyTitle>*/}
        <StyledHeader header={"Heart to Heart"} subHeader={"From the Frantic Fanfic team."}/>

        <BodyText>Hiiiii! Zee here. Hope you enjoy this game that we made!!!! It took us three months to make!! This
            idea
            sprung from trying to come up with a fun virtual party night for my
            friends! But I think lots of people will enjoy it. Please do not lie about your age, that part is very
            important. That's about it. Don't be too mean to Michael. He tried very hard and learned so much making
            it!!! And I did too!! Thank you for playing!!! Sorry about any bugs in advance lol.</BodyText>

        <BodyText>Hi, Michael here! This is the first time I've done something like this so I hope you all
            have fun!
            There are some bugs and quirks with this game and will try my best to fix them as I learn about
            them!</BodyText>

        <BodyText>X.O.X.O. FRANTIC FANFIC </BodyText>

        <BodyText>P.S. - LIKE THE GAME? WANT TO SEND US A LITTLE SOMETHIN SOMETHIN? SEND US A KO-FI <a
            href={"https://ko-fi.com/franticfanfic"}>HERE</a>.
        </BodyText>

    </Body>
}

