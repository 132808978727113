import React from "react";
import { useAppSelector } from "./redux/store";
import { ConnectionStatus } from "./redux/slices/meta";
import "./styles.css"

type Props = {
    hideConnected?: boolean
    leftLabel?: string
}

const AppBottom: React.FunctionComponent<Props> = props => {
    // Get the detailed connection status instead of just boolean
    const connectionStatus = useAppSelector(state => state.meta.connectionStatus);

    // Format the status message based on the connection state
    const getConnectionStatusText = (): string => {
        switch (connectionStatus) {
            case ConnectionStatus.CONNECTED:
                return "player connected";
            case ConnectionStatus.CONNECTING:
                return "connecting to server";
            case ConnectionStatus.RECONNECTING:
                return "reconnecting to server";
            case ConnectionStatus.CONNECTION_ERROR:
                return "connection error";
            case ConnectionStatus.CONNECTION_LOST:
                return "connection lost";
            case ConnectionStatus.DISCONNECTED:
            default:
                return "player disconnected";
        }
    };

    // Get the appropriate CSS class for the status indicator
    const getStatusIndicatorClass = (): string => {
        switch (connectionStatus) {
            case ConnectionStatus.CONNECTED:
                return "status-indicator-connected";
            case ConnectionStatus.CONNECTING:
            case ConnectionStatus.RECONNECTING:
                return "status-indicator-connecting";
            case ConnectionStatus.CONNECTION_ERROR:
            case ConnectionStatus.CONNECTION_LOST:
                return "status-indicator-error";
            case ConnectionStatus.DISCONNECTED:
            default:
                return "status-indicator-disconnected";
        }
    };

    return (
        <div className="hidden z-50 md:flex flex-row-reverse justify-between mx-4 h-5">
            {connectionStatus !== undefined ? (
                <div className={props.hideConnected ? "app-bottom-label hidden" : "app-bottom-label"}>
                    <span className={`status-indicator ${getStatusIndicatorClass()}`}></span>
                    <span className="ml-2">{getConnectionStatusText()}</span>
                </div>
            ) : ''}

            {props.leftLabel !== undefined ? (
                <div className="app-bottom-label">
                    {props.leftLabel}
                </div>
            ) : ''}
        </div>
    );
}

export default AppBottom;