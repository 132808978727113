import React from "react";
import zine1 from "../../images/blog17/ZINE1.png";
import zine2 from "../../images/blog17/ZINE2.png";
import zine3 from "../../images/blog17/ZINE3.png";
import zine4 from "../../images/blog17/ZINE4.png";
import zine5 from "../../images/blog17/ZINE5.png";
import BodyTitle from "../../components/common/body-title/body-title";
import Body from "../../components/common/body/body";
import BodyText from "../../components/common/body-text/body-text";
import BodyList, {BodyListItem} from "../../components/common/body-list/body-list";

export let blogTitle = "ANOTHER ANOTHER MONTH"

export let blogAuthor = "Posted by Zee / Wednesday Oct 20 2021"

export let blogContent = <Body>

    <BodyTitle>We've been busy ahhhhhhhhhhh what else is new??</BodyTitle>
    <BodyText>We're doin lots of things haha yeah nice awesome. Let's get to the UPDATE!!</BodyText>

    <BodyTitle>solo mode!!!!!!!!!!!!!</BodyTitle>
    <BodyText>Big news! Solo play is here! You don't need any friends! Just a connection to the internet and a desire to write.
        We included forced prompts for the first time! If you like them a lot, let us know on our Bluesky (<a
            rel={"noreferrer"} target={"_blank"} href={"https://bsky.app/profile/franticfanfic.com"}>@franticfanfic.com</a>) and we
        might add them as an option to our party modes!</BodyText>

    <BodyTitle> counter is fixed!!!</BodyTitle>
    <BodyText>OK so turns out the hit counter was a little broken. We're dialing it back a bit. Because Oops. </BodyText>

    <BodyTitle>night mode!</BodyTitle>
    <BodyText>We got some feedback that the bright pink background was hard on the eyes. So we added night mode to give your
        eyes a rest! Access it in the name screen</BodyText>

    <BodyTitle>issue with database crying. fixed that.</BodyTitle>
    <BodyText>This is boring. We just had to fix something because the game stopped working a bit. Hehe sorry.</BodyText>


    <BodyText>We also took a survey on Reddit and they let us know they wanted an auto shuffle option for solo mode. So you can
        still write 5 characters and have the game pick for you or choose solo OTP mode. So many options!</BodyText>

    <BodyTitle>backend and frontend overhaul. </BodyTitle>
    <BodyText>Michael reduced spaghetti. He introduced redux. This will probably help solve weird issues with flickering
        or getting sent back to the main screen.</BodyText>

    <BodyText>Michael also fixed issues with audience breaking games... and fixed issues with censoring breaking games.</BodyText>

    <BodyText>THANKS MICHAEL!!!!!</BodyText>

    <BodyTitle>PHYSICAL ZINE COPIES NOW FOR SALE!</BodyTitle>
    <a href={"https://zeekayartwork.square.site/product/physical-copy-frantic-fanfic-a-zine/57?cp=true&sa=true&sbp=false&q=false"}>ZINES
        ZINES ZINES</a>

    <a href={"https://zeekayartwork.square.site/product/physical-copy-frantic-fanfic-a-zine/57?cp=true&sa=true&sbp=false&q=false"}>
        <img src={zine1} alt={zine1}
             width={"100%"}
             height={"auto"}/>
    </a>

    <a href={"https://zeekayartwork.square.site/product/physical-copy-frantic-fanfic-a-zine/57?cp=true&sa=true&sbp=false&q=false"}>
        <img src={zine2} alt={zine2}
             width={"100%"}
             height={"auto"}/>
    </a>

    <a href={"https://zeekayartwork.square.site/product/physical-copy-frantic-fanfic-a-zine/57?cp=true&sa=true&sbp=false&q=false"}>
        <img src={zine3} alt={zine3}
             width={"100%"}
             height={"auto"}/>
    </a>

    <a href={"https://zeekayartwork.square.site/product/physical-copy-frantic-fanfic-a-zine/57?cp=true&sa=true&sbp=false&q=false"}>
        <img src={zine4} alt={zine4}
             width={"100%"}
             height={"auto"}/>
    </a>
    <a href={"https://zeekayartwork.square.site/product/physical-copy-frantic-fanfic-a-zine/57?cp=true&sa=true&sbp=false&q=false"}>
        <img src={zine5} alt={zine5}
             width={"100%"}
             height={"auto"}/></a>

    <BodyTitle>Edit: October 23</BodyTitle>
    <BodyText>We fixed a number of bugs we become aware of after our release on the 20th</BodyText>
    <BodyList>
        <BodyListItem>players were unable to clear the story writing field</BodyListItem>
        <BodyListItem>removed some of the flickering/transition pages</BodyListItem>
        <BodyListItem>increased time limit in reading from 20 minutes to 2 hours</BodyListItem>
        <BodyListItem>randomizer buttons were duplicated in game settings page for solo mode</BodyListItem>
        <BodyListItem>reacts were not calculated properly and were displayed as 0</BodyListItem>
        <BodyListItem>when entering characters, the text field would be cleared whenever someone else submitted their characters
        </BodyListItem>
    </BodyList>

</Body>

export let blog = {
    title: blogTitle,
    author: blogAuthor,
    content: blogContent
}